import { useStaticQuery, graphql } from "gatsby"

{/*
  React Hook that fetches all Wordpress Posts.
  They will appear as the News items in /news/.
*/}

export const useNewsPosts = () => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost(sort: {order: DESC, fields: date}) {
          nodes {
            id
            title
            date
            content
            rsvp {
              rsvp
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpPost.nodes
}
