import React from "react"

import Home from "../layouts/home"
import SEO from "../components/seo"
import NewsItem from "../components/list/newsItem"

import {useNewsPosts} from "../hooks/useNewsPosts"

export default function News() {
  {/* Code that fetches all the News items (as Posts from Wordpress) */}
  const news = useNewsPosts()
  return (
    <Home>
      <SEO title="news" />
      <div className='subtitle'>Our latest and greatest!</div>

      {/* If there are News items, displays them as a NewsItem component */}
      {news && news.map(item=> (
        <NewsItem
          key={item.id}
          title={item.title}
          content={item.content}
          date={item.date}
          thumbnail={item.featuredImage?.node?.localFile?.childImageSharp?.fluid}
          rsvp={item.rsvp}/>
      ))}
    </Home>
  )
}
