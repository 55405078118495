import React from "react"
import Img from "gatsby-image"

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {getNewsItemWrapper, getNewsItemTitle, getNewsItemRSVP, getNewsItemImage} from '../../utils'

function NewsItem({date, content, title, thumbnail, rsvp}) {

  const breakpoints = useBreakpoint()
  {/*
    This takes care of rendering a single Module item in the Landing page.
  */}
  return (

  	<li
      className='news-item'
      style={getNewsItemWrapper(breakpoints)}>

      {/* Responsinevess */}
      <div style={getNewsItemTitle(breakpoints)}>
        {/* Title */}
        <div className='list-title'>{title}</div>

        {/* Content */}
  	    <div dangerouslySetInnerHTML={{ __html: content }} />

        {/* Checks if there is an RSVP link and renders it */}
        {rsvp.rsvp &&
          <div
            className='RSVP'
            style={getNewsItemRSVP(breakpoints)}>
            <a href={rsvp.rsvp}>learn more<span>&#x2197;</span> </a>
          </div>
        }
      </div>

      {/* Checks if there is an image and displays it */}
      <div style={getNewsItemImage(breakpoints)}>
        {thumbnail &&
          <Img
            fluid={thumbnail}
            alt={title}
            />
        }
      </div>

    </li>
   )
}

export default NewsItem
